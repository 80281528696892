const $ = window.Bliss
const $$ = $.$

// const DISAPPEAR_ANIMATION_NAME = "menu-disappear"

export class MenuHandler {
  constructor(el, json) {
    this.el = el
    this.preventOpeningMenu = true
    this.menu = $("[data-menu]", this.el)
    this.navigation = $(".navigation")
    this.navigationMeta = $(".navigation-meta")
    this.menuLinks = $$("[data-menu-link]", this.menu)
    this.mobileMenuLinks = $$("[data-mobile-menu-item]", this.el)
    this.viewer = $("[data-overlay-viewer]", this.el)
    this.contentViewer = $("[data-overlay-viewer-content]", this.viewer)
    this.menuList = $("[data-overlay-viewer-menu-list]", this.viewer)
    this.menuAsJson = json
    this.slideBack = $$("[data-mobile-slide-back]", this.el)
    this.slideBottom = $("[data-menu-slide='1']", this.el)
    this.closeButton = $("[data-toggle-mobile-nav]", this.el)
    this.slideTop = $("[data-menu-slide='2']", this.el)
    this.slideContainer = $("[data-menu-container]", this.el)
    this.current = null
    this.navigationOverlay = $(".navigation-overlay")

    this.fetchedContent = {}
    this.childrenNodes = []

    this.addListeners()
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const height = Number(entry.target.offsetHeight)
        if (height > 0) this.navigationOverlay.style.height = `${height}px`
      })
    })
    $$(".navigation-link__children", this.navigation).forEach(
      (childrenList) => {
        this.resizeObserver.observe(childrenList)
      },
    )
  }

  addListeners() {
    // Listen on link hovers

    const linkHoverOpen = (link) => {
      if (link.parentElement.dataset?.overlayHasChildren) {
        this.open()
        this.menuLinks.forEach((el) =>
          el.parentElement.classList.remove("active"),
        )
        this.menuLinks.forEach((el) => {
          if (!el.classList.contains("active")) {
            el.classList.add("deactivate")
          } else {
            el.classList.remove("deactivate")
          }
        })
        link.parentElement.classList.add("active")
      } else {
        this.close()
        this.menuLinks.forEach((el) =>
          el.parentElement.classList.remove("active"),
        )
        link.parentElement.classList.add("active")
      }
    }

    this.menuLinks.forEach((link) => {
      link.addEventListener("mouseover", (e) => {
        e.preventDefault()
        if (!this.preventOpeningMenu) linkHoverOpen(link)
      })
      link.addEventListener("focus", (e) => {
        e.preventDefault()
        linkHoverOpen(link)
      })
    })

    this.mobileMenuLinks.forEach((link, index) => {
      const children = link.parentNode.querySelector(
        "[data-overlay-menu-children]",
      )
      this.childrenNodes.push(children)

      link.addEventListener("click", (e) => {
        e.preventDefault()

        this.openSlide()

        this.slideContainer.appendChild(this.childrenNodes[index])
        this.current = this.childrenNodes[index]
      })
    })
    this.slideBack.forEach((el) => {
      el.addEventListener("click", () => {
        this.slideTop.classList.remove("open")
        this.slideBottom.classList.add("open")
        if (this.current) {
          this.slideContainer.removeChild(this.current)
          this.current = null
        }
      })
    })

    this.closeButton.addEventListener("click", () => {
      this.closeSlide()
    })

    this.navigation.addEventListener("mouseleave", () => {
      this.close()
      this.preventOpeningMenu = false
    })

    this.navigation.addEventListener("mouseover", () => {
      this.preventOpeningMenu = false
    })

    this.navigation.addEventListener("focusin", () => {
      this.preventOpeningMenu = false
    })

    this.navigationMeta.addEventListener("mouseover", () => {
      this.close()
    })

    this.navigationMeta.addEventListener("focusin", () => {
      this.close()
    })

    $("#content").addEventListener("focusin", () => {
      this.close()
    })

    $$(
      ".navigation-link:not(.navigation-link--accordion)",
      this.navigationMeta,
    ).forEach((link) => {
      link.addEventListener("click", () => {
        this.preventOpeningMenu = true
      })
    })
  }

  openSlide() {
    this.slideBottom.classList.remove("open")
    this.slideTop.classList.add("open")
  }

  closeSlide() {
    this.slideBottom.classList.add("open")
    this.slideTop.classList.remove("open")
    if (this.current) {
      this.slideContainer.removeChild(this.current)
      this.current = null
    }
  }

  initMenu(e) {
    e.classList.add("active")
  }

  returnImage(childrenImage, id) {
    let target = null
    childrenImage.filter((image, index) => {
      if (image.dataset.overlayViewerImage === id) {
        target = childrenImage[index]
      }
    })
    return target
  }

  addChildrenListener() {
    if (this.current) {
      const content = this.fetchedContent[this.current]
      const children = $$("[data-overlay-children]", content)
      const childrenImage = $$("[data-overlay-viewer-image]", content)
      this.prevImage = null

      children.forEach((child) => {
        child.addEventListener("mouseenter", (e) => {
          const targetImageContainer = this.returnImage(
            childrenImage,
            e.target.id,
          )
          const targetImage = $("img", targetImageContainer)
          childrenImage.forEach((el) => {
            const image = $("img", el)

            if (image.dataset.imgId === this.prevImage.dataset.imgId) {
            } else {
              image.style.width = `${0}%`
            }
          })

          targetImageContainer.style.zIndex = children.length + 1

          targetImage.style.width = `${100}%`
        })
        child.addEventListener("mouseleave", (e) => {
          const targetImageContainer = this.returnImage(
            childrenImage,
            e.target.id,
          )
          targetImageContainer.style.zIndex = children.length
          const targetImage = $("img", targetImageContainer)
          this.prevImage = targetImage

          setTimeout(() => {
            this.prevImage.style.width = `${0}%`
          }, 350)
        })
      })
    }
  }

  async appendContent(output) {
    await this.removeCurrentContent()
    this.viewer.appendChild(output)
    // this.viewer.scrollTop = 0
  }

  removeCurrentContent() {
    return new Promise((resolve, reject) => {
      // console.log(this.current);
      if (this.current) {
        try {
          const content = this.fetchedContent[this.current]
          this.viewer.removeChild(content)
          resolve()
          // content.addEventListener('animationend', disappearListener)
          // content.classList.add('disappear')
        } catch (err) {
          reject(err)
        }
      } else {
        resolve()
      }
    })
  }

  fetchContent(id) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (this.fetchedContent[id]) {
        resolve(this.fetchedContent[id])
        return
      }

      try {
        this.viewer.dataset.loading = true
        this.open()
        let target = {}
        const menuData = this.menuAsJson
        const menuContent = menuData.menu_content
        menuContent.filter((page, index) => {
          if (page.id === id) {
            target = menuContent[index]
          }
        })
        const domparser = new DOMParser()
        const data = domparser.parseFromString(target.output, "text/html")
        const rootNode = data.getRootNode()
        target = rootNode.querySelector(".navigation-overlay__menu")
        this.fetchedContent[id] = target
        resolve(target)
        this.viewer.dataset.loading = false
      } catch (err) {
        reject(err)
      }
    })

    // create header
    // create submenu
  }

  open() {
    this.el.dataset.openMenu = true
    document.body.dataset.overlayMenu = true
  }

  async close() {
    this.menuLinks.forEach((el) => el.parentElement.classList.remove("active"))
    this.menuLinks.forEach((el) => el.classList.remove("deactivate"))

    this.el.dataset.openMenu = false
    document.body.dataset.overlayMenu = false
    this.current = null
  }

  updateLinkStyles(activeLink = this.current) {
    this.menuLinks.forEach((link) => {
      link.dataset.active = link.id === activeLink
    })
  }
}
