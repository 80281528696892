/* globals gettext, interpolate */

import { useEffect, useRef, useState } from "preact/hooks"
import getCookie from "./utils/getCookie"

export const ApplicationForm = ({ endpoint, formEl, fieldsData, type }) => {
  const [fieldsAndErrorData, setFieldsAndErrorData] = useState(fieldsData)
  const [state, setState] = useState("")
  const [message, setMessage] = useState("")

  const [accountNameOf, setAccountNameOf] = useState("")
  const [hasCustomerNumber, setHasCustomerNumber] = useState(false)
  const [supportTypeSportsActivity, setSupportTypeSportsActivity] =
    useState(false)
  const [supportTypePhysiotherapy, setSupportTypePhysiotherapy] =
    useState(false)
  const [supportTypeMentalHealth, setSupportTypeMentalHealth] = useState(false)
  const [supportTypeRelief, setSupportTypeRelief] = useState(false)
  const [supportTypeOther, _setSupportTypeOther] = useState(false)
  const [criteriaModulators, setCriteriaModulators] = useState(false)
  const [criteriaTransplant, setCriteriaTransplant] = useState(false)
  const [criteriaSupport, setCriteriaSupport] = useState(false)
  const [explanation_1, setExplanation_1] = useState(false)
  const [explanation_2, setExplanation_2] = useState(false)
  const [explanation_3, setExplanation_3] = useState(false)
  const [explanation_4, setExplanation_4] = useState(false)

  const setErrors = (errors) => {
    const newFieldsAndErrorData = { ...fieldsAndErrorData }
    Object.keys(fieldsAndErrorData).forEach((k) => {
      newFieldsAndErrorData[k].errors = errors[k] || ""
    })
    setFieldsAndErrorData(newFieldsAndErrorData)
  }

  const privacyPolicyRef = useRef()

  useEffect(() => {
    formEl.onsubmit = async (e) => {
      e.preventDefault()
      setState("pending")

      const response = await postData(new FormData(e.target))

      if (response.state === "success") {
        setState("success")
        setMessage(response.message)
      } else if (response.state === "error") {
        console.error(response.errors)
        setState("")
        setErrors(response.errors)
        setMessage("")
      } else {
        console.error(response.message || "Unexpected error occurred.")
        setState("fatal")
        setMessage(response.message || "Unexpected error occurred.")
        console.debug(response)
      }
    }
  }, [])

  useEffect(() => {
    if (privacyPolicyRef?.current) {
      privacyPolicyRef.current.innerHTML = gettext(
        "application_form_privacy_policy_message",
      )
    }
  }, [privacyPolicyRef])

  useEffect(() => {
    const firstError = formEl.querySelector(".form-error")
    if (firstError) firstError.scrollIntoView({ behavior: "smooth" })
  }, [fieldsAndErrorData])

  useEffect(() => {
    const successMessage = formEl.querySelector(".success-message")
    if (successMessage) successMessage.scrollIntoView({ behavior: "smooth" })
  }, [message])

  const postData = async (data) => {
    const response = await fetch(endpoint, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: data,
    })

    return response.json()
  }

  const transferAddress = (e) => {
    e.preventDefault()
    const address = document.getElementsByName("child_address")[0].value
    const postalCode = document.getElementsByName("child_postal_code")[0].value
    const location = document.getElementsByName("child_location")[0].value
    const street_no = document.getElementsByName("child_street_no")[0].value

    document.getElementsByName("parent_1_address")[0].value = address
    document.getElementsByName("parent_1_postal_code")[0].value = postalCode
    document.getElementsByName("parent_1_location")[0].value = location
    document.getElementsByName("parent_1_street_no")[0].value = street_no
    document.getElementsByName("parent_2_address")[0].value = address
    document.getElementsByName("parent_2_postal_code")[0].value = postalCode
    document.getElementsByName("parent_2_location")[0].value = location
    document.getElementsByName("parent_2_street_no")[0].value = street_no
  }

  return (
    <>
      {(state === "" || state === "pending") && (
        <>
          {type === "application-underage" ? (
            <>
              <h2>{gettext("1. Our details")}</h2>
              <h3>{gettext("Details of the CF-affected child")}</h3>
              <RadioField
                name="child_gender"
                {...fieldsAndErrorData.child_gender}
                required={true}
              />
              <div class="fields-grid g-1-1">
                <CharField
                  name="child_firstname"
                  {...fieldsAndErrorData.child_firstname}
                  required={true}
                />
                <CharField
                  name="child_lastname"
                  {...fieldsAndErrorData.child_lastname}
                  required={true}
                />
              </div>
              <div class="fields-grid g-3-1">
                <CharField
                  name="child_address"
                  {...fieldsAndErrorData.child_address}
                  required={true}
                />
                <CharField
                  name="child_street_no"
                  {...fieldsAndErrorData.child_street_no}
                  required={true}
                />
              </div>
              <div class="fields-grid g-1-3">
                <NumberField
                  name="child_postal_code"
                  {...fieldsAndErrorData.child_postal_code}
                  required={true}
                />
                <CharField
                  name="child_location"
                  {...fieldsAndErrorData.child_location}
                  required={true}
                />
              </div>
              <DateField
                name="child_date_of_birth"
                {...fieldsAndErrorData.child_date_of_birth}
                required={true}
              />
              <p>
                {gettext(
                  "Address of the child also for parents / legal representatives.",
                )}
              </p>
              <button
                class="button no-decoration button--primary"
                type="button"
                onClick={transferAddress}
              >
                {gettext("Transfer address")}
              </button>
              <h3>{gettext("Details of the legal guardians")}</h3>
              <RadioField
                name="parent_1_affiliation"
                {...fieldsAndErrorData.parent_1_affiliation}
                required={true}
              />
              <RadioField
                name="parent_1_salutation"
                {...fieldsAndErrorData.parent_1_salutation}
                required={true}
              />
              <div class="fields-grid g-1-1">
                <CharField
                  name="parent_1_firstname"
                  {...fieldsAndErrorData.parent_1_firstname}
                  required={true}
                />
                <CharField
                  name="parent_1_lastname"
                  {...fieldsAndErrorData.parent_1_lastname}
                  required={true}
                />
              </div>
              <div class="fields-grid g-3-1">
                <CharField
                  name="parent_1_address"
                  {...fieldsAndErrorData.parent_1_address}
                  required={true}
                />
                <CharField
                  name="parent_1_street_no"
                  {...fieldsAndErrorData.parent_1_street_no}
                  required={true}
                />
              </div>
              <div class="fields-grid g-1-3">
                <NumberField
                  name="parent_1_postal_code"
                  {...fieldsAndErrorData.parent_1_postal_code}
                  required={true}
                />
                <CharField
                  name="parent_1_location"
                  {...fieldsAndErrorData.parent_1_location}
                  required={true}
                />
              </div>
              <MailField
                name="parent_1_email"
                {...fieldsAndErrorData.parent_1_email}
                required={true}
              />
              <TeleField
                name="parent_1_phone"
                {...fieldsAndErrorData.parent_1_phone}
                required={true}
              />
              <RadioField
                name="parent_2_affiliation"
                {...fieldsAndErrorData.parent_2_affiliation}
                required={false}
              />
              <RadioField
                name="parent_2_salutation"
                {...fieldsAndErrorData.parent_2_salutation}
                required={false}
              />
              <div class="fields-grid g-1-1">
                <CharField
                  name="parent_2_firstname"
                  {...fieldsAndErrorData.parent_2_firstname}
                  required={false}
                />
                <CharField
                  name="parent_2_lastname"
                  {...fieldsAndErrorData.parent_2_lastname}
                  required={false}
                />
              </div>
              <div class="fields-grid g-3-1">
                <CharField
                  name="parent_2_address"
                  {...fieldsAndErrorData.parent_2_address}
                  required={false}
                />
                <CharField
                  name="parent_2_street_no"
                  {...fieldsAndErrorData.parent_2_street_no}
                  required={false}
                />
              </div>
              <div class="fields-grid g-1-3">
                <NumberField
                  name="parent_2_postal_code"
                  {...fieldsAndErrorData.parent_2_postal_code}
                  required={false}
                />
                <CharField
                  name="parent_2_location"
                  {...fieldsAndErrorData.parent_2_location}
                  required={false}
                />
              </div>
              <MailField
                name="parent_2_email"
                {...fieldsAndErrorData.parent_2_email}
                required={false}
              />
              <TeleField
                name="parent_2_phone"
                {...fieldsAndErrorData.parent_2_phone}
                required={false}
              />
            </>
          ) : null}
          {type === "application-legalage" ? (
            <>
              <h2>{gettext("1. Details")}</h2>
              <h3>{gettext("Personal details")}</h3>
              <RadioField
                name="gender"
                {...fieldsAndErrorData.gender}
                required={true}
              />
              <div class="fields-grid g-1-1">
                <CharField
                  name="firstname"
                  {...fieldsAndErrorData.firstname}
                  required={true}
                />
                <CharField
                  name="lastname"
                  {...fieldsAndErrorData.lastname}
                  required={true}
                />
              </div>
              <div class="fields-grid g-3-1">
                <CharField
                  name="address"
                  {...fieldsAndErrorData.address}
                  required={true}
                />
                <CharField
                  name="street_no"
                  {...fieldsAndErrorData.street_no}
                  required={true}
                />
              </div>
              <div class="fields-grid g-1-3">
                <NumberField
                  name="postal_code"
                  {...fieldsAndErrorData.postal_code}
                  required={true}
                />
                <CharField
                  name="location"
                  {...fieldsAndErrorData.location}
                  required={true}
                />
              </div>
              <DateField
                name="date_of_birth"
                {...fieldsAndErrorData.date_of_birth}
                required={true}
              />
              <MailField
                name="email"
                {...fieldsAndErrorData.email}
                required={true}
              />
              <TeleField
                name="phone"
                {...fieldsAndErrorData.phone}
                required={true}
              />
            </>
          ) : null}
          <h3>{gettext("Bank details")}</h3>
          <CharField name="iban" {...fieldsAndErrorData.iban} required={true} />
          {type === "application-underage" ? (
            <FormGroup>
              <SelectField
                name="account_name_of"
                {...fieldsAndErrorData.account_name_of}
                setValue={setAccountNameOf}
                required={true}
              />
              {accountNameOf === "other" ? (
                <CharField
                  name="account_name_of_other"
                  {...fieldsAndErrorData.account_name_of_other}
                  required={true}
                />
              ) : null}
            </FormGroup>
          ) : null}
          <RadioField
            name="has_customer_number"
            {...fieldsAndErrorData.has_customer_number}
            setValue={setHasCustomerNumber}
            required={true}
          />
          {hasCustomerNumber === "has_number" ? (
            <CharField
              name="customer_number"
              {...fieldsAndErrorData.customer_number}
              required={true}
            />
          ) : null}
          <h2>{gettext("2. Health-promoting measures")}</h2>
          <h5>
            {type === "application-underage"
              ? gettext(
                  "We would like support for the following health-promoting measures*:",
                )
              : gettext(
                  "I would like support for the following health-promoting measures*:",
                )}
          </h5>
          <FormGroup>
            <CheckField
              name="support_type_sports_activity"
              {...fieldsAndErrorData.support_type_sports_activity}
              setBool={setSupportTypeSportsActivity}
              value={supportTypeSportsActivity}
            />
            {supportTypeSportsActivity ? (
              <CharField
                noLabel={true}
                name="support_type_sports_activity_description"
                {...fieldsAndErrorData.support_type_sports_activity_description}
                required={true}
              />
            ) : null}

            <CheckField
              name="support_type_physiotherapy"
              {...fieldsAndErrorData.support_type_physiotherapy}
              setBool={setSupportTypePhysiotherapy}
              value={supportTypePhysiotherapy}
            />
            {supportTypePhysiotherapy ? (
              <CharField
                noLabel={true}
                name="support_type_physiotherapy_description"
                {...fieldsAndErrorData.support_type_physiotherapy_description}
                required={true}
              />
            ) : null}

            <CheckField
              name="support_type_mental_health"
              {...fieldsAndErrorData.support_type_mental_health}
              setBool={setSupportTypeMentalHealth}
              value={supportTypeMentalHealth}
            />
            {supportTypeMentalHealth ? (
              <CharField
                noLabel={true}
                name="support_type_mental_health_description"
                {...fieldsAndErrorData.support_type_mental_health_description}
                required={true}
              />
            ) : null}

            {type === "application-underage" ? (
              <>
                <CheckField
                  name="support_type_relief"
                  {...fieldsAndErrorData.support_type_relief}
                  setBool={setSupportTypeRelief}
                  value={supportTypeRelief}
                />
                {supportTypeRelief ? (
                  <CharField
                    noLabel={true}
                    name="support_type_relief_description"
                    {...fieldsAndErrorData.support_type_relief_description}
                    required={true}
                  />
                ) : null}
              </>
            ) : null}
          </FormGroup>

          <h2>{gettext("3. contribution requested")}</h2>
          <NumberField
            name="annual_costs"
            {...fieldsAndErrorData.annual_costs}
            required={true}
          />
          <NumberField
            name="amount_requested"
            {...fieldsAndErrorData.amount_requested}
            required={true}
          />
          <p>
            <i>
              {gettext(
                "The payout depends on the amount of the fund volume and can vary annually, with a maximum of 1000 CHF.",
              )}
            </i>
          </p>
          {type === "application-underage" ? (
            <>
              <h2>
                {gettext("4. Do any of these criteria apply to your family?")}
              </h2>
              <FormGroup>
                <CheckField
                  name="criteria_modulators"
                  {...fieldsAndErrorData.criteria_modulators}
                  setBool={setCriteriaModulators}
                  value={criteriaModulators}
                />
                <CheckField
                  name="criteria_support"
                  {...fieldsAndErrorData.criteria_support}
                  setBool={setCriteriaSupport}
                  value={criteriaSupport}
                />
              </FormGroup>
            </>
          ) : (
            <>
              <h2>{gettext("4. Do any of these criteria apply to you?")}</h2>
              <FormGroup>
                <CheckField
                  name="criteria_modulators"
                  {...fieldsAndErrorData.criteria_modulators}
                  setBool={setCriteriaModulators}
                  value={criteriaModulators}
                />
                <CheckField
                  name="criteria_transplant"
                  {...fieldsAndErrorData.criteria_transplant}
                  setBool={setCriteriaTransplant}
                  value={criteriaTransplant}
                />
                <CheckField
                  name="criteria_support"
                  {...fieldsAndErrorData.criteria_support}
                  setBool={setCriteriaSupport}
                  value={criteriaSupport}
                />
              </FormGroup>
            </>
          )}
          <h2>{gettext("5. Declaration")}</h2>
          <p>
            {type === "application-underage"
              ? gettext("By submitting this form we confirm,")
              : gettext("By submitting this form I confirm,")}
          </p>
          <FormGroup>
            <CheckField
              name="explanation_1"
              {...fieldsAndErrorData.explanation_1}
              setBool={setExplanation_1}
              value={explanation_1}
            />
            <CheckField
              name="explanation_2"
              {...fieldsAndErrorData.explanation_2}
              setBool={setExplanation_2}
              value={explanation_2}
            />
            <CheckField
              name="explanation_3"
              {...fieldsAndErrorData.explanation_3}
              setBool={setExplanation_3}
              value={explanation_3}
            />
            <CheckField
              name="explanation_4"
              {...fieldsAndErrorData.explanation_4}
              setBool={setExplanation_4}
              value={explanation_4}
            />
          </FormGroup>

          <p ref={privacyPolicyRef} />
          <div class="form-group__next">
            {!supportTypeSportsActivity &&
            !supportTypePhysiotherapy &&
            !supportTypeMentalHealth &&
            (type === "application-underage" ? !supportTypeRelief : true) ? (
              <p class="error">
                {gettext("At least one of the measures must be selected.")}
              </p>
            ) : null}

            {!explanation_1 ||
            !explanation_2 ||
            !explanation_3 ||
            !explanation_4 ? (
              <p class="error">
                {gettext(
                  "All statements must be confirmed before the application can be sent.",
                )}
              </p>
            ) : null}

            <button
              type="submit"
              class="button no-decoration button--primary"
              value={gettext("Submit application")}
              disabled={
                state === "pending" ||
                !explanation_1 ||
                !explanation_2 ||
                !explanation_3 ||
                !explanation_4 ||
                (!supportTypeSportsActivity &&
                  !supportTypePhysiotherapy &&
                  !supportTypeMentalHealth &&
                  (type === "application-underage"
                    ? !supportTypeRelief
                    : true) &&
                  !supportTypeOther)
              }
            >
              <span>{gettext("Submit application")}</span>
            </button>
          </div>
        </>
      )}
      {message && (
        <div
          class="success-message success-message--not-bold"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </>
  )
}

const CharField = (data) => {
  const [length, setLength] = useState(0)
  return (
    <div class="form-group">
      <fieldset class="form-fieldset">
        {data.noLabel ? "" : <Label {...data} />}
        <input
          id={data.name}
          type="text"
          name={data.name}
          required={data.required}
          maxlength={data.max_length}
          onInput={(e) => setLength(e.target.value.length)}
          placeholder={
            data.noLabel
              ? ""
              : data.name !== "iban"
                ? data.label
                : "CHXX XXXX XXXX XXXX XXXX X"
          }
        />
        {data.max_length && length === data.max_length && (
          <span class="maxlength-warning">
            {interpolate(gettext("maximal %s Zeichen"), [data.max_length])}
          </span>
        )}
      </fieldset>
    </div>
  )
}

const NumberField = (data) => {
  return (
    <div class="form-group">
      <fieldset class="form-number">
        <Label {...data} />
        <input
          id={data.name}
          type="number"
          name={data.name}
          required={data.required}
          placeholder={data.label}
        />
      </fieldset>
    </div>
  )
}

const TeleField = (data) => {
  return (
    <div class="form-group">
      <fieldset class="form-tele">
        <Label {...data} />
        <input
          id={data.name}
          type="tel"
          name={data.name}
          required={data.required}
          placeholder={data.label}
        />
      </fieldset>
    </div>
  )
}

const MailField = (data) => {
  return (
    <div class="form-group">
      <fieldset class="form-mail">
        <Label {...data} />
        <input
          id={data.name}
          type="email"
          name={data.name}
          required={data.required}
          placeholder={data.label}
        />
      </fieldset>
    </div>
  )
}

// const TextField = (data) => {
//   return (
//     <div class="form-group">
//       <fieldset class="form-fieldset">
//         <Label {...data} />
//         <textarea
//           id={data.name}
//           name={data.name}
//           required
//           placeholder={data.label}
//         />
//       </fieldset>
//     </div>
//   )
// }
//

const RadioField = (data) => {
  return (
    <FormGroup>
      <fieldset class="form-fieldset">
        <legend class="fieldset-legend">{data.label}</legend>
        <div class="fields-flex">
          {data.choices.map((c, i) => {
            return (
              <label>
                <input
                  type="radio"
                  key={i}
                  name={data.name}
                  value={c[0]}
                  onChange={
                    data.setValue
                      ? (e) => {
                          data.setValue(e.target.value)
                        }
                      : () => {}
                  }
                  required={data.required}
                />
                {c[1]}
              </label>
            )
          })}
        </div>
      </fieldset>
    </FormGroup>
  )
}

const SelectField = (data) => {
  return (
    <fieldset class="form-fieldset">
      <Label {...data} />
      <select
        id={data.name}
        name={data.name}
        onChange={
          data.setValue
            ? (e) => {
                data.setValue(e.target.value)
              }
            : () => {}
        }
        required={data.required}
      >
        {data.choices.map((c, i) => {
          return (
            <option key={i} value={c[0]} selected={c[0] === "CH"}>
              {c[1]}
            </option>
          )
        })}
      </select>
    </fieldset>
  )
}

const DateField = (data) => {
  return (
    <FormGroup>
      <fieldset class="form-fieldset">
        <Label {...data} />
        <input
          id={data.name}
          type="date"
          name={data.name}
          required={data.required}
        />
      </fieldset>
    </FormGroup>
  )
}

const CheckField = (data) => {
  return (
    <fieldset class="form-checkbox">
      <Label {...data}>
        <input
          id={data.name}
          type="checkbox"
          name={data.name}
          required={data.required}
          onChange={
            data.setBool
              ? () => {
                  data.setBool(!data.value)
                }
              : null
          }
        />
      </Label>
    </fieldset>
  )
}

// const RadioField = (data) => {
//   return (
//     <div class="form-group">
//       <fieldset class="form-radio">
//         <Label {...data} />
//         <input type="radio" name={data.name} required={data.required} />
//       </fieldset>
//     </div>
//   )
// }

const Label = (data) => {
  return (
    <>
      {!data.children ? (
        <label for={data.name}>
          {data.label}
          {data.required && "*"}
        </label>
      ) : (
        <label>
          {data.children}
          <span>
            {data.label}
            {data.required && "*"}
          </span>
        </label>
      )}
      {data.errors && <div class="form-error">{data.errors.join(" ")}</div>}
    </>
  )
}

const FormGroup = (props) => {
  return <div class="form-group">{props.children}</div>
}
